import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BannerFold from "./pages/BannerFold";
import AboutUsFold from "./pages/AboutUsFold";
import MedicinesFold from "./pages/MedicinesFold";
import CountingFold from "./pages/CountingFold";
import ContactUsFold from "./pages/ContactUsFold";
import ScrollToTop from "./helpers/ScrollToTop";
import MedicineSlideOne from "./json/MedicineSlideOne.json";
import MedicineSlideTwo from "./json/MedicineSlideTwo.json";
import MedicineSlideThree from "./json/MedicineSlideThree.json";
import MedicineSlideFour from "./json/MedicineSlideFour.json";
import MedicineSlideFive from "./json/MedicineSlideFive.json";

const App = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Header />
      <BannerFold />
      <AboutUsFold />
      <MedicinesFold
        slideOne={MedicineSlideOne}
        slideTwo={MedicineSlideTwo}
        slideThree={MedicineSlideThree}
        slideFour={MedicineSlideFour}
        slideFive={MedicineSlideFive}
      />
      <CountingFold />
      {/* <ContactUsFold /> */}
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default App;
