import React from "react";
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { FaMobileRetro } from "react-icons/fa6";
import { TbWorldWww } from "react-icons/tb";
import logo from "../assets/images/png-biolife-science-logo.jpg";
import FaceBookIcon from "../assets/social-icons/FaceBook.png";
import LinkedInIcon from "../assets/social-icons/LinkedIn.png";

const Footer = () => {
  let currentWindowWidth = window.screen.width;

  return (
    <a>
      <a class="anchor" id="contact"></a>

      <div
        style={{
          backgroundColor: "#5897b5",

          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: currentWindowWidth < 900 ? "center" : "start",
        }}
        id="contactus"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}

        >
          <div
            className="footerSecondDiv container"
          // style={{ width: "100%", borderBottom: "1px solid grey" }}
          >
            <div className="footerTextDiv">
              <div className="footerBrandingTextDiv">

                <img
                  alt="biotec_logo"
                  className="header_logo mb-3"
                  src={logo}
                  style={{
                    height: "auto",
                    width: "100%",
                    // borderRadius: "85px",
                  }}
                />

                {/* <p
                  className="footerContactUsText"
                >
                  PNG BIO LIFE SCIENCE
                </p> */}
              </div>

              <div className="footerAdressTextDiv">
                <p className="footerContactUsText">Contact Us</p>
                <p className="footerAddressText">
                  Ground floor H.No 20-56 Aparna Nilayam
                  <br />
                  Ambedkar Nagar Road No.1,
                  <br />
                  Opp. Andhra Hospital Gollapudi Village,
                  <br />
                  Vijayawada, NTR
                  <br />
                  Andhra Pradesh - 521225, India.
                </p>

                <p className="footerAddressText d-md-flex">
                  <div className="iconsDiv me-2">
                    <IoCallOutline />
                    <a className="footerContactUs" href="tel:9594952564">
                      022-31886028
                    </a>
                  </div>
                  <div className="iconsDiv">
                    <FaMobileRetro />
                    <a className="footerContactUs" href="tel:9594952564">
                      +91 9594952564
                    </a>
                  </div>
                </p>

                <p className="footerAddressText footerIconsDiv">
                  <TbWorldWww />
                  <span><a
                    className="footerContactUs"
                    href="https://www.pngbiolifescience.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    pngbiolifescience.com
                  </a>, <a
                    className="footerContactUs"
                    href="https://www.pngbiotec.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                      pngbiotec.com
                    </a></span>

                </p>

                <p className="footerAddressText footerIconsDiv">
                  <span className=""> <AiOutlineMail /></span>

                  <span>
                    <a
                      className="footerContactUs"
                      href="mailto:pngbiolifescience@gmail.com"
                    >
                      pngbiolifescience@gmail.com
                    </a>, <a
                      className="footerContactUs"
                      href="mailto:pravinwaghela@pngbiolifescience.com"
                    >
                      pravinwaghela@pngbiolifescience.com
                    </a></span>


                </p>

                <a
                  href="https://www.facebook.com/profile.php?id=100092214745891"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={FaceBookIcon}
                    alt="facebook"
                    width="40px"
                    height="40px"
                    // style={{ marginBottom: "10px" }}
                    className="cursor-pointer social-icons hover:scale-110"
                  />
                </a>

                <a
                  href="https://www.linkedin.com/in/png-biotec-334380273/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={LinkedInIcon}
                    alt="linked in"
                    width="40px"
                    height="40px"
                    style={{ marginLeft: "10px" }}
                    className="cursor-pointer social-icons hover:scale-110"
                  />
                </a>

                {/* <div className="z-50 pb-4 my-7 laptop:mt-5 mobile:my-4 flex gap-5 bigPc:gap-8">
            <a href="" target="_blank" rel="noreferrer">
              <img
                src={FaceBookIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>

            <a href="" target="_blank" rel="noreferrer">
              <img
                src={InstagramIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>

            <a href="" target="_blank" rel="noreferrer">
              <img
                src={LinkedInIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>

            <a href="" target="_blank" rel="noreferrer">
              <img
                src={TwitterIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>
          </div> */}
              </div>
            </div>

            <div className="footerMapImgDiv">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3824.617969827672!2d80.57462047589868!3d16.54537342651391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35ee5000843ff7%3A0x4400f8e62459cd41!2s4-20%2C%20Gollapudi%2C%20Vijayawada%2C%20Andhra%20Pradesh%20521225!5e0!3m2!1sen!2sin!4v1700055566589!5m2!1sen!2sin"
                className="footerMapIFrame"
                title="map"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <p className="footerCopyrightText">copyright@pnglife 2023-24</p>
          {/* <p className="footerCopyrightText">
          Created with 💚 by turningpointtech.in
        </p> */}
        </div>
      </div>
    </a>
  );
};

export default Footer;
