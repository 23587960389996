import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const CountingFold = () => {
  const [startCounter, setStartCounter] = useState(false);
  const counterRef = useRef(null);

  const medicineFoldRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  let Data = [
    {
      countNum: 176,
      countTitle: "Successful Projects",
    },
    {
      countNum: 30,
      countTitle: "Industries Covered",
    },
    {
      countNum: 27,
      countTitle: "Geographies Covered",
    },
    {
      countNum: 37,
      countTitle: "Experienced Consultants",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const element = counterRef.current;
      if (
        element &&
        window.pageYOffset > element.offsetTop - window.innerHeight
      ) {
        setStartCounter(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const element = counterRef.current;
    gsap.fromTo(
      element.querySelector(".counterFoldHeadingTextForAnimation"),
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfCounterFold"),
          start: "top -380%",
          end: "+=55%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = counterRef.current;
    gsap.fromTo(
      element.querySelector(".countingRow"),
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfCounterFold"),
          start: "top -420%",
          end: "+=60%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <section
      className="counterSection startAnimationOfCounterFold"
      ref={counterRef}
    >
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-12">
            <div className="aboutUsFoldSecDiv counterDiv">
              <p className="aboutUsFoldHeadingText countingHeadText counterFoldHeadingTextForAnimation">
                Unparalleled Experience
              </p>

              <div className="row countingRow">
                {Data.map((value, index) => (
                  <div
                    className="counterup_style--1 col-lg-3 col-md-3 col-sm-6 col-12"
                    key={index}
                  >
                    <h5 className="counter">
                      <CountUp
                        start={startCounter ? 0 : null}
                        end={value?.countNum}
                        duration={6}
                      />
                    </h5>
                    <p className="description">{value.countTitle}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountingFold;
