import React, { useEffect, useState } from "react";
import logo from "../assets/images/png-biolife-science-logo.jpg";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useNavigate, NavLink } from "react-router-dom"
import { Link, Events, animateScroll as scroll } from 'react-scroll';


const Header = () => {
  let currentWindowWidth = window.screen.width;
  const history = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  const [visible, setVisible] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleSetActive = (to) => {
    setActiveLink(to);
  };

  // Scroll to top when the logo is clicked
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <header className={`header ${scrolled ? 'header__shrink' : ''}`}>

        <div className='container '>
          <div className="logo">
            <img src={logo} alt="Logo" />
            {/* <NavLink to="/" className="text-decoration-none">
              <h4 className='fw-bold border-0 text-decoration-none' style={{ color: "#265073" }} onClick={scrollToTop}>PNG BIO LIFE SCIENCE</h4>

            </NavLink> */}
          </div>
          <div className="nav-links d-none d-lg-block">
            <Link
              to="/"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='fw-bold text-dark border-0 text-decoration-none'
              style={{ cursor: "pointer" }}
              onClick={scrollToTop}
            >
              Home
            </Link>
            <Link
              to="aboutus"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`fw-bold text-dark border-0 text-decoration-none ${activeLink === 'aboutus' ? 'active' : ''
                }`}
              onSetActive={handleSetActive}
              style={{ cursor: "pointer" }}

            >
              About
            </Link>
            <Link
              to="medicines"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`fw-bold text-dark border-0 text-decoration-none ${activeLink === 'medicines' ? 'active' : ''
                }`}
              style={{ cursor: "pointer" }}
              onSetActive={handleSetActive}
            >
              Products
            </Link>
            <Link
              to="contactus"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className={`fw-bold text-dark border-0 text-decoration-none ${activeLink === 'contactus' ? 'active' : ''
                }`}
              onSetActive={handleSetActive}
              style={{ cursor: "pointer" }}

            >
              Contact Us
            </Link>
          </div>
          <div className=" d-block d-lg-none">
            <MenuOutlined className="mobIcon" onClick={() => showDrawer()} />
          </div>
        </div>


      </header>

      <Drawer
        placement="right"
        className="mobileHeader"
        onClose={onClose}
        visible={visible}
      >
        <ul>
          <li
            onClick={() => {
              setVisible(false);
            }}
          >
            <a href="#about">About Us</a>
          </li>
          <li
            onClick={() => {
              setVisible(false);
            }}
          >
            <a href="#medicines">Products</a>
          </li>
          <li
            onClick={() => {
              setVisible(false);
            }}
          >
            <a href="#contact">Contact Us</a>
          </li>
        </ul>
      </Drawer>
    </>
  );
};

export default Header;
