import React from "react";
import bannerTwo from "../assets/images/BANNER-2-removebg.png";
import card_structure from "../assets/images/bg-chemical.jpg"
import structural_img from "../assets/images/molecule.png"

import { AiOutlineArrowDown } from "react-icons/ai";

const BannerFold = () => {
  let currentWindowWidth = window.screen.width;
  const navigateToAboutUsFold = async () => { };

  return (
    <section
      style={{
        height: "100vh",
        width: "100%",
        backgroundImage: `url(${card_structure})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="clientSection firstFold firstFoldBgClass"
    >
      <div className="container bannerFoldSecondDiv">
        <div className="row align-items-center justify-content-center d-flex bannerFoldSecondDiv">
          <div className="col-12 col-lg-6 d-md-none order-lg-2">
            <div className="bannerFoldSecondDiv">
              <div className="bannerFoldThirdDiv">
                <div className="bannerFoldImageDiv">
                  <img
                    src={structural_img}
                    alt="banner"
                    className="bannerFoldImage bounce-effect"
                    style={{ float: "right" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-12 order-lg-1">
            <div className="fade-in-banner-text bannerFoldFourthDiv">
              <span className="bannerNormalText">Welcome to,</span>
              <span className="bannerBoldText">PNG BIO LIFE SCIENCE</span>
              <span
                className={`${currentWindowWidth > 500
                  ? "line-to-animate animation-typewriter"
                  : ""
                  } bannerNormalText`}
              >
                We have revolutionised many products of pharmaceutical & medicines. <br />
              </span>
              {/* Add any additional content here */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerFold;
