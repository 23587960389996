import React, { useEffect, useRef } from "react";
import dna from "../assets/images/dna.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const AboutUsFold = () => {
  let currentWindowWidth = window.screen.width;
  const aboutUsFoldRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const element = aboutUsFoldRef.current;
    gsap.fromTo(
      element.querySelector(".aboutUsFoldHeadingText"),
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfAboutUsFold"),
          start: "top -20%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = aboutUsFoldRef.current;
    gsap.fromTo(
      element.querySelector(".aboutUsFoldSummaryTextPara1"),
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfAboutUsFold"),
          start: "top -35%",
          end: "+=30%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = aboutUsFoldRef.current;
    gsap.fromTo(
      element.querySelector(".aboutUsFoldSummaryTextPara2"),
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfAboutUsFold"),
          start: "top -45%",
          end: "+=30%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = aboutUsFoldRef.current;
    gsap.fromTo(
      element.querySelector(".aboutUsFoldSummaryTextPara3"),
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfAboutUsFold"),
          start: "top -60%",
          end: "+=30%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <a>
      <a class="anchor" id="about"></a>

      <div
        ref={aboutUsFoldRef}
        className="startAnimationOfAboutUsFold"
        style={{
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        id="aboutus"
      >
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center"></div>
          <div className="col-md-12">
            <div className="aboutUsFoldSecDiv">
              <p className="aboutUsFoldHeadingText">ABOUT US</p>

              <img
                className="img-150 lazyloaded "
                src={dna}
                alt="dna"
                // data-ll-status="loaded"
              />

              <p className="aboutUsFoldSummaryText1 aboutUsFoldSummaryTextPara1">
                &emsp;Established in the year{" "}
                <span style={{ textTransform: "uppercase", fontWeight: "bold", color: "#265073" }}>
                  2013 in Mumbai, PNG BIOTEC
                </span>{" "}
                 are one of the prominent{" "}
                <span style={{ textTransform: "capitalize", fontWeight: "bold", color: "#265073" }}>
                  Manufacturers, PNG BIO LIFE SCIENCE, suppliers, and exporters of
                  superior-quality pharmaceuticals and medicinal drugs
                </span>
                . Our products are manufactured using premium-grade chemicals and
                raw materials in compliance with the prevailing industrial quality
                standards. They are highly demanded by the chemical and
                pharmaceutical industries. Our products are highly acclaimed for
                their excellent quality, long shelf life, and budget-friendly
                prices. Our products are approved by experienced quality auditors,
                and we guarantee premium quality. We are able to cater to the bulk
                and urgent orders of our clients with utmost ease, owing to the
                presence of large stocks. All the chemical and medicinal products
                manufactured by us are offered to clients within the specified
                timeframe in customised packaging sizes.
              </p>

              <p className="aboutUsFoldSummaryText1 aboutUsFoldSummaryTextPara2">
                &emsp;Our spacious warehouse facility helps us stay safe and
                efficient storage of our products. Our warehouse facility is
                moisture-free, dust-free, and hygienic. Our products are stored in
                well-sealed packaging in order to retain their quality and prevent
                the entry of moisture and dust particles. Manufactured using raw
                materials that are procured from reputed vendors in the market, our
                products are effective in treating several diseases. Their fast
                action and potency against the disease-causing bacteria is well
                known. Their lengthy Shelf life makes them easy to store for long
                periods of time. We offer to our esteemed clients in multiple
                specifications as per their requirements. Our products are also
                exported overseas through reliable shipping modes.
              </p>

              <p className="aboutUsFoldSummaryText1 aboutUsFoldSummaryTextPara3">
                &emsp;Under the efficient leadership of{" "}
                <span style={{ textTransform: "uppercase", fontWeight: "bold", color: "#265073" }}>
                  Mr. Pravin Dilip Waghela
                </span>
                , we have been successful in making a name for ourselves in the
                market. Due to his sharp business acumen, we have garnered a loyal
                clientele base. The emphasis that we place on ethics has been
                instrumental in establishing our worthiness as a credible
                organisation.
              </p>
            </div>
          </div>

        </div>


      </div>
    </a>
  );
};

export default AboutUsFold;
