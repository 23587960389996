import React, { useEffect, useRef } from "react";
import injection_med from "../assets/images/injection_med.png";
import pills from "../assets/images/whitePowderPlate.png";
import pills_tab from "../assets/images/pills.png";
// import Powder from "../assets/images/powder_med.png";
import Powder from "../assets/images/whitePowderBowl.png";
import tablet from "../assets/images/blue-tablet.png";
import color_pills from "../assets/images/colorfull-pills.png";
import pills_water from "../assets/images/pills-water.png";
import needles from "../assets/images/some-pills-needles.png";
import greenPowder from "../assets/images/powder_med.png";
import brownPowder from "../assets/images/paprika-powder.png";
import topView from "../assets/images/top-view-crushed-pills-removebg-preview.png";
import chcolate from "../assets/images/chocolate-malt-bottle-removebg-preview.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Card, Col, Row } from "antd";
import pills_liquid from "../assets/images/closeup-two-syringes-beside-vial-vaccine-flu-covid-19-measles-other-diseases-removebg-preview.png";
import different_pills from "../assets/images/top-view-pills-with-container-removebg-preview.png";
import close_tablet from "../assets/images/closeup-assorted-pills-white-background-removebg-preview.png";
import card_structure from "../assets/images/card_structure.jpg"

const MedicinesFold = ({
  slideOne,
  slideTwo,
  slideThree,
  slideFour,
  slideFive,
}) => {
  // console.log(slideOne, "slideOne");
  // console.log(slideTwo, "slideTwo");
  // console.log(slideThree, "slideThree");

  let currentWindowWidth = window.screen.width;
  const medicineFoldRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldHeadingTextForAnimation"),
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -90%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFirstRow"),
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -110%",
          end: "+=55%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineSecondRow"),
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -180%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineThirdRow"),
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -250%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFourthRow"),
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -320%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFifthRow"),
      {
        opacity: 0,
        y: 80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -350%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  const cardImagesOne = [pills, brownPowder, topView];

  const cardImagesTwo = [Powder, greenPowder, chcolate];

  const cardImagesThree = [injection_med, needles, pills_liquid];

  const cardImagesFour = [pills_water, tablet, close_tablet];

  const cardImagesFive = [color_pills, different_pills, pills_tab];

  return (
    <a>
      <a class="anchor" id="medicines"></a>

      <section
        ref={medicineFoldRef}
        className="medicineSection startAnimationOfMedicineFold"
        id="medicines"
      >
        <div className="container mb-5">
          <div className="row d-flex align-items-center justify-content-center mt-5">
            <div className="col-12 col-lg-12">
              <p className="aboutUsFoldHeadingText medicineFoldHeadingTextForAnimation text-center">
                Products
              </p>
            </div>
          </div>

          <Row gutter={16} className="medicineFirstRow">
            {Array.from({ length: Math.ceil(slideFour.length / 8) }).map(
              (_, index) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  key={index}
                  style={{ marginBottom: "16px" }}
                >
                  <Card className="main-card p-0" bordered={false} style={{ backgroundColor: "#265073" }}>
                    <div className="card-content text-center">
                      <img
                        className="services-img"
                        src={cardImagesOne[index % cardImagesOne.length]}
                        alt={`Card ${index + 1}`}
                      ></img>
                      <div className="medcineName">
                        <ul className="fa-ul mt-0">
                          {slideFour
                            .slice(index * 8, (index + 1) * 8)
                            .map((item, id) => (
                              <li key={id}>
                                <span className="fa-li">
                                  <i className="bi bi-circle me-2"></i>
                                </span>
                                <span className="content-para">
                                  {item?.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Card>
                </Col>
              )
            )}
          </Row>

          <Row gutter={16} className="mt-4 medicineSecondRow">
            {Array.from({ length: Math.ceil(slideFive.length / 8) }).map(
              (_, index) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  key={index}
                  style={{ marginBottom: "16px" }}
                >
                  <Card className="main-card p-0" bordered={false} style={{ backgroundColor: "#265073" }}>
                    <div className="card-content text-center">
                      <img
                        className="services-img"
                        src={cardImagesTwo[index % cardImagesTwo.length]}
                        alt={`Card ${index + 1}`}
                      ></img>
                      <div className="medcineName">
                        <ul className="fa-ul mt-0">
                          {slideFive
                            .slice(index * 8, (index + 1) * 8)
                            .map((item, id) => (
                              <li key={id}>
                                <span className="fa-li">
                                  <i className="bi bi-circle me-2"></i>
                                </span>
                                <span className="content-para">
                                  {item?.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Card>
                </Col>
              )
            )}
          </Row>

          <Row gutter={16} className="mt-4 medicineThirdRow">
            {Array.from({ length: Math.ceil(slideThree.length / 7) }).map(
              (_, index) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  key={index}
                  style={{ marginBottom: "16px" }}
                >
                  <Card className="main-card p-0" bordered={false} style={{ backgroundColor: "#265073" }}>
                    <div className="card-content text-center">
                      <img
                        className="services-img"
                        src={cardImagesThree[index % cardImagesThree.length]}
                        alt={`Card ${index + 1}`}
                      ></img>
                      <div className="medcineName">
                        <ul className="fa-ul mt-0">
                          {slideThree
                            .slice(index * 7, (index + 1) * 7)
                            .map((item, id) => (
                              <li key={id}>
                                <span className="fa-li">
                                  <i className="bi bi-circle me-2"></i>
                                </span>
                                <span className="content-para">
                                  {item?.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Card>
                </Col>
              )
            )}
          </Row>

          <Row gutter={16} className="mt-4 medicineFourthRow">
            {Array.from({ length: Math.ceil(slideOne.length / 7) }).map(
              (_, index) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  key={index}
                  style={{ marginBottom: "16px" }}
                >
                  <Card className="main-card p-0" bordered={false} style={{ backgroundColor: "#265073" }}>
                    <div className="card-content text-center">
                      <img
                        className="services-img"
                        src={cardImagesFour[index % cardImagesFour.length]}
                        alt={`Card ${index + 1}`}
                      ></img>
                      <div className="medcineName">
                        <ul className="fa-ul mt-0">
                          {slideOne
                            .slice(index * 7, (index + 1) * 7)
                            .map((item, id) => (
                              <li key={id}>
                                <span className="fa-li">
                                  <i className="bi bi-circle me-2"></i>
                                </span>
                                <span className="content-para">
                                  {item?.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Card>
                </Col>
              )
            )}
          </Row>

          <Row gutter={16} className="mt-4 medicineFifthRow">
            {Array.from({ length: Math.ceil(slideTwo.length / 7) }).map(
              (_, index) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  key={index}
                  style={{ marginBottom: "16px" }}
                >
                  <Card className="main-card p-0" bordered={false} style={{ backgroundColor: "#265073" }}>
                    <div className="card-content text-center">
                      <img
                        className="services-img"
                        src={cardImagesFive[index % cardImagesFive.length]}
                        alt={`Card ${index + 1}`}
                      ></img>
                      <div className="medcineName">
                        <ul className="fa-ul mt-0">
                          {slideTwo
                            .slice(index * 7, (index + 1) * 7)
                            .map((item, id) => (
                              <li key={id}>
                                <span className="fa-li">
                                  <i className="bi bi-circle me-2"></i>
                                </span>
                                <span className="content-para">
                                  {item?.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </div>
      </section>
    </a>
  );
};

export default MedicinesFold;
